@import './src/scss/variables';
@import './src/scss/app';

$head: $color10;
$tail: $color15;

.widget {
	&__toss {
		&-coin {
			display: flex;
			justify-content: center;
		}
	}

	&__result {
		&-stat {
			display: flex;
	    flex-direction: column;
	    gap: .5rem;
      border: 1px solid;
      padding: .5rem;
      min-width: $min-width;
		}

		&-overview {
		  display: flex;
      justify-content: space-between;

      .title {
        display: flex;
        gap: .5rem;
        align-items: center;
      }

      .heads {
		    display: flex;
		    flex-direction: column;
		    align-items: flex-start;
		    gap: .5rem;
      }

      .tails {
		    display: flex;
		    flex-direction: column;
		    align-items: flex-end;
		    gap: .5rem;
      }
		}

		&-ratio {
			position: relative;

		  .heads {
		    background-color: $head;
		    height: .25rem;
		    position: absolute;
		    z-index: 2;
		  }

		  .total {
		    background-color: $tail;
		    width: 100%;
		    height: .25rem;
		  }
		}
	}
}

.widget__result-table {
  max-height: 350px;
	overflow-x: auto;
}

#coin {
  cursor: pointer;
  width: 250px;
  height: 250px;
  transform-style: preserve-3d;
  perspective: 1000px;
  border-radius: 50%;
  align-self: center;
  user-select: none;
  opacity: 1 !important;
  box-sizing: border-box;

  &.flipping {
	  cursor: unset;
	  pointer-events: none;
	  transition: transform 1.7s linear;
	}

	 > * {
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  backface-visibility: hidden;
	  border-radius: 50%;
	}
}

#heads {
  background-color: $head;
  z-index: 2;
}

#tails {
  background-color: $tail;
  transform: rotateX(180deg);
}

#heads, #tails {
  display: flex;
  justify-content: center;
  align-items: center;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size5;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: .5rem solid rgba($color2, .6);
    color: $color2;
    gap: .5rem;
  }
}